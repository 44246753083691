@import "./../theme-bootstrap";

.product-awards {
  &__item {
    display: block;
    &-icon {
      display: none;
    }
  }
}

.product-full__awards--icon .product-awards {
  &__item {
    display: inline-block;
    padding-#{$rdirection}: 10px;
    vertical-align: top;
    @include breakpoint($portrait-up) {
      padding-#{$rdirection}: 25px;
    }
    &-icon {
      display: block;
      max-width: 64px;
      height: 50px;
      margin: auto;
    }
    &-label {
      display: none;
    }
  }
}
